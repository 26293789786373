import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { useLocation } from "react-router";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { FormattedMessage } from "@mnemonic/intl";
import * as UrlUtils from "@mnemonic/pure-utils/UrlUtils";
import { extractErrorMessage } from "@mnemonic/redux-utils";
import { LoginBox, LoginBoxCallout, LoginBoxContent, LoginBoxHeader, LoginBoxSubHeader, } from "../../Components/Login/Layout";
import { OpenIDIcon } from "../../Components/OpenIDIcon";
import { useRequestRedirectURIByProviderIDQuery } from "../../Redux/Service/openIdApi";
import { useTypedSelector } from "../../Redux/createStore";
import generateNonce from "../../utils/generateNonce";
import { setOpenIdFlowSessionStorage } from "../../utils/openIdFlowSessionStorage";
import { LoginOpenIDMissingProvider } from "./LoginOpenIDMissingProvider";
import RedirectWrapper from "./RedirectWrapper";
import { saveLoginDetails } from "./utils/saveLoginDetails";
export const LoginOpenID = () => {
    const { nextUrl } = useTypedSelector((state) => state.global);
    const location = useLocation();
    const [openIdState, setOpenIdState] = useState({
        autoAuthenticate: false,
        providerID: null,
        nonce: null,
    });
    const { nonce, autoAuthenticate, providerID } = openIdState;
    const { provider, authenticate } = queryString.parse(location?.search);
    const base64EncodedState = UrlUtils.encodeUrlCriteria({ type: "authentication", nextURI: nextUrl, nonce });
    const { data, isLoading, error } = useRequestRedirectURIByProviderIDQuery({ criteria: { provider: providerID, state: base64EncodedState } }, { skip: !providerID || !base64EncodedState });
    const providerURI = data?.data?.providerURI;
    const errorMessage = extractErrorMessage(error);
    const onAuthenticate = () => {
        setOpenIdFlowSessionStorage("preAuthRequestState", base64EncodedState);
        if (providerURI) {
            setOpenIdFlowSessionStorage("preAuthResponseState", new URL(providerURI).searchParams.get("state"));
            window.location.replace(providerURI);
        }
        saveLoginDetails({ method: "openid", byPassResponseCode: true });
    };
    useEffect(() => {
        // Generate random nonce. Will be used to legitimate incoming redirects - avoid CSRF attacks
        const nonce = generateNonce();
        localStorage.setItem("openid.nonce", nonce);
        // New provider should override eventual existing providerID in localStorage
        if (provider) {
            localStorage.setItem("openid.provider", provider);
        }
        const providerID = localStorage.getItem("openid.provider") ?? null;
        setOpenIdState({ nonce, autoAuthenticate: authenticate === "true", providerID });
    }, []);
    // Trigger onAuthenticate when authenticate param is set to true
    useEffect(() => {
        if (autoAuthenticate) {
            setOpenIdFlowSessionStorage("autoAuthenticate", "true");
            onAuthenticate();
        }
    }, [autoAuthenticate, providerID]);
    //Capture the initial nextUrl to session storage to log later
    useEffect(() => {
        setOpenIdFlowSessionStorage("initialNextUrl", String(nextUrl));
    }, [nextUrl]);
    return (<RedirectWrapper>
      <LoginBox loading={isLoading}>
        <LoginBoxHeader />
        <LoginBoxContent>
          {errorMessage && <LoginBoxCallout type="error">{errorMessage}</LoginBoxCallout>}
          <LoginBoxSubHeader method={<FormattedMessage id="login.openid" defaultMessage="Single sign on with OpenID"/>}/>
          <Grid container direction="column">
            <Grid container justifyContent="center">
              <OpenIDIcon sx={(theme) => ({
            display: "block",
            height: 96,
            marginBottom: theme.spacing(2),
            mx: "auto",
            width: 96,
        })} opacity={"0.54"}/>
            </Grid>
            {providerID ? (<Grid container justifyContent="flex-end">
                <Button variant="contained" color="secondary" disabled={isLoading} onClick={() => {
                setOpenIdFlowSessionStorage("autoAuthenticate", "false");
                onAuthenticate();
            }}>
                  <FormattedMessage id="login.authenticate" defaultMessage="Authenticate"/>
                </Button>
              </Grid>) : (<LoginOpenIDMissingProvider />)}
          </Grid>
        </LoginBoxContent>
      </LoginBox>
    </RedirectWrapper>);
};
