/**
 *
 * Determines the status of the password length requirement for UI rendering.
 * Used to decide the appropriate icon and color in the interface.
 *
 * @param {IGetLengthStatus} param0 - Object containing newPassword and isMinLengthMet.
 * @param {string} param0.newPassword - The new password being validated.
 * @param {boolean} param0.isMinLengthMet - Whether the password meets the minimum length requirement.
 * @returns {TCircleIconStatus} The status: "error", "success", or "disabled" to render the corresponding icon and color.
 *
 * */
export function getLengthRequirementStatus({ newPassword, isMinLengthMet }) {
    if (!isMinLengthMet && newPassword !== "")
        return "error";
    if (isMinLengthMet)
        return "success";
    return "disabled";
}
/**
 *
 * Determines the status of the password breach requirement for UI rendering.
 * Used to decide the appropriate icon and color in the interface.
 *
 * @param {IGetBreachStatus} param0 - Object containing passwordValid and isMinLengthMet.
 * @param {IFetchBreachStatusResponse} param0.passwordValid - The response object indicating password breach status.
 * @param {boolean} param0.isMinLengthMet - Whether the password meets the minimum length requirement.
 * @returns {TCircleIconStatus} The status: "error", "success", or "disabled" to render the corresponding icon and color.
 *
 * */
export function getBreachRequirementStatus({ passwordValid, isMinLengthMet }) {
    if (isMinLengthMet && passwordValid?.state !== "ok")
        return "error";
    if (isMinLengthMet && passwordValid?.state === "ok")
        return "success";
    return "disabled";
}
