import React from "react";
import AvTimerOutlined from "@mui/icons-material/AvTimerOutlined";
import KeyOutlined from "@mui/icons-material/KeyOutlined";
import LockIcon from "@mui/icons-material/Lock";
import SmartphoneOutlined from "@mui/icons-material/SmartphoneOutlined";
import { useTheme } from "@mui/material/styles";
import { defineMessages } from "@mnemonic/intl";
import { AD3Icon, OpenIDIcon } from "../../Components/Icons/icons";
import { commonMessages } from "../../commonMessages";
const customSortOrder = ["LDAP", "TOTP", "SIGNATURE", "OPENID", "SMS", "PASSWORD"];
const getIcon = (method) => {
    const theme = useTheme();
    const icons = {
        LDAP: <AD3Icon htmlColor={theme.palette.text.primary}/>,
        TOTP: <AvTimerOutlined htmlColor={theme.palette.text.primary} fontSize="large"/>,
        SIGNATURE: <KeyOutlined htmlColor={theme.palette.text.primary} fontSize="large"/>,
        OPENID: <OpenIDIcon htmlColor={theme.palette.text.primary}/>,
        SMS: <SmartphoneOutlined htmlColor={theme.palette.text.primary} fontSize="large"/>,
        PASSWORD: <LockIcon htmlColor={theme.palette.text.primary} fontSize="large"/>,
    };
    return icons[method] || "";
};
/**
 * Returns a list of available authentication methods with their associated metadata.
 *
 * This function sorts the provided authentication methods by a custom order and then
 * maps each method to an object containing its key, URL path, icon, and text.
 *
 * @param {TAuthenticationMethod[]} methods - An array of authentication methods.
 * @returns {IMethodsList[]} A list of objects containing metadata for each authentication method.
 *    Each object includes:
 *    - `key`: The original authentication method.
 *    - `to`: The URL path for the method in lowercase.
 *    - `icon`: The icon associated with the method.
 *    - `primary`: The main display text for the ListItem.
 *    - `secondary`: The supporting or additional text for ListItem.
 */
export const getAvailableMethods = (methods) => sortAuthenticationMethodsByCustomOrder(methods).map((method) => {
    return {
        key: method,
        to: `/login/${method.toLowerCase()}`,
        icon: getIcon(method),
        ...getText(method),
    };
});
export const sortAuthenticationMethodsByCustomOrder = (array) => {
    if (!array || !array?.length)
        return [];
    return [...array].sort((a, b) => {
        const indexA = customSortOrder.indexOf(a);
        const indexB = customSortOrder.indexOf(b);
        const orderA = indexA === -1 ? customSortOrder.length : indexA;
        const orderB = indexB === -1 ? customSortOrder.length : indexB;
        return orderA - orderB;
    });
};
export const messages = defineMessages({
    LDAP: {
        id: "login.ldap",
        defaultMessage: "Log in using your mnemonic AD3 password",
    },
    TOTP: {
        id: "login.totp",
        defaultMessage: "Log in using password and a TOTP generated by an authenticator app, like Microsoft Authenticator",
    },
    SIGNATURE: {
        id: "login.signature",
        defaultMessage: "Log in with fingerprint or a physical security key, like YubiKey",
    },
    OPENID: {
        id: "login.openId",
        defaultMessage: "Log in with OpenID Connect",
    },
    SMS: {
        id: "login.sms",
        defaultMessage: "Log in using password an an SMS code",
    },
    PASSWORD: {
        id: "login.password",
        defaultMessage: "Log in using password",
    },
    loginUsing: {
        id: "login.loginUsing",
        defaultMessage: "Log in using",
    },
});
const getText = (method) => ({
    primary: commonMessages[method]?.defaultMessage || method,
    secondary: messages[method]?.defaultMessage || "",
});
