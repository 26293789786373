import { FORM_ERROR } from "final-form";
import { extractErrorMessage, extractFieldErrorMessage } from "@mnemonic/redux-utils";
import { getAvailableMethods } from "../Authenticate/utils";
export const preferredAuthenticationMethods = ["SIGNATURE", "TOTP"];
/**
 * Maps backend errors to form fields.
 *
 * Extracts error messages for specified fields, where field names must match the backend's error keys.
 * If no field-specific errors are found, a general form error is returned.
 *
 * @param {unknown} e - The error object from the backend.
 * @param {string[]} fields - Array of field names, matching the backend's error keys.
 * @returns {Record<string, string | null> | { FORM_ERROR: string }}
 *          - Field-specific errors or a general form error.
 *
 * @note The field names provided in the `fields` array must exactly match the error keys returned by the backend.
 * If they don't, no field-specific errors will be mapped, and the general form error will be returned instead.
 */
export const mapErrors = (e, fields) => {
    const supportedFieldErrors = fields.reduce((acc, field) => {
        acc[field] = extractFieldErrorMessage(e, field);
        return acc;
    }, {});
    if (Object.entries(supportedFieldErrors).some(([, value]) => Boolean(value))) {
        return supportedFieldErrors;
    }
    return { [FORM_ERROR]: extractErrorMessage(e) || JSON.stringify(e, null, 2) };
};
/**
 * Returns preferred authentication methods that are enabled.
 * Currently, TOTP and WebAuthn are preferred.
 *
 * @param {IAuthenticationMethod[]} methods - List of authentication methods to check.
 * @returns {IAuthenticationMethod[]} - Returns the authentication methods we want to recommend the user to set up.
 */
export const getPreferredAuthMethods = (methods) => {
    const preferred = [];
    const totpMethod = methods.find((m) => m.method === "TOTP" && m.enabled);
    if (totpMethod) {
        preferred.push(totpMethod);
    }
    // Process SIGNATURE (WebAuthn):
    const signatureMethod = methods.find((m) => m.method === "SIGNATURE" && m.enabled);
    if (signatureMethod) {
        preferred.push(signatureMethod);
    }
    return preferred;
};
export const createAuthenticationListItems = (availableAuthMethods) => {
    const availableGoodAuthenticationMethods = availableAuthMethods
        ?.filter(({ method, enabled }) => enabled && preferredAuthenticationMethods.includes(method))
        ?.map((item) => item.method);
    const listItemsWithModifiedLink = getAvailableMethods(availableGoodAuthenticationMethods)?.map((item) => ({
        ...item,
        to: "", //Blank link will not refresh or attempt to redirect. This lets us redirect users with RTK actions instead.
    }));
    return listItemsWithModifiedLink;
};
export const setSessionStorage = (method) => {
    const action = method === "TOTP" ? "totpInit" : "webauthn";
    const storage = window.sessionStorage;
    const stringifiedState = JSON.stringify({ action });
    storage.setItem("state", stringifiedState);
};
