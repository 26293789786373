import React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useIntl } from "@mnemonic/intl";
import * as UrlUtils from "@mnemonic/pure-utils/UrlUtils";
import { extractErrorMessage } from "@mnemonic/redux-utils";
import { LoginBoxCallout } from "../../Components/Login/Layout";
import { OpenIDIcon } from "../../Components/OpenIDIcon";
import { useRequestRedirectURIOpenIDAuthorizationQuery } from "../../Redux/Service/authorizeApi";
import { commonMessages } from "../../commonMessages";
export const AuthorizationWithOpenID = ({ nextURI, operation }) => {
    const { formatMessage } = useIntl();
    const { data, isLoading, error } = useRequestRedirectURIOpenIDAuthorizationQuery({
        criteria: {
            operation,
            nextURI,
        },
    }, { skip: !operation || !nextURI });
    const errorMessage = extractErrorMessage(error);
    const { providerURI, stateID } = data?.data || {};
    return (<Grid container direction="column">
      {errorMessage && <LoginBoxCallout type="error">{errorMessage}</LoginBoxCallout>}

      <Grid container justifyContent="center">
        <OpenIDIcon sx={(theme) => ({
            display: "block",
            height: theme.spacing(12),
            marginBottom: theme.spacing(2),
            marginLeft: "auto",
            marginRight: "auto",
            width: 96,
        })} opacity="0.54"/>
      </Grid>
      <Grid container justifyContent="flex-end">
        <Button disabled={isLoading} style={{ marginRight: 8 }} onClick={() => window.location.replace(nextURI)}>
          {formatMessage(commonMessages.Cancel)}
        </Button>
        <Button variant="contained" color="secondary" disabled={isLoading} onClick={() => {
            if (providerURI) {
                const base64EncodedState = UrlUtils.encodeUrlCriteria({ type: "authorization", id: stateID, nextURI });
                window.location.replace(`${providerURI}&state=${base64EncodedState}`);
            }
        }}>
          {formatMessage(commonMessages.Authorize)}
        </Button>
      </Grid>
    </Grid>);
};
